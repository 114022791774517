import useAppStore from "@app-store/app";
import useDocsStore from "@app-store/docs";
import useGlobalStore from "@app-store/global";
import { EventAI, trackUserEvent } from "@app-utilities/app-insights";
import EventManager from "@app-utilities/event-manager";
import { ProductRepository } from "@app-utilities/products";
import SectionAliasesMap from "@app-utilities/section-aliases";
import { type MenuItem, QMenu } from "@qamf/lighthouse";
import { BContainer, BSpinner } from "bootstrap-vue-next";
import $ from "cash-dom";
import { clone } from "lodash-es";
import { computed, defineComponent, nextTick, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
interface ITocSections {
	id: string;
	title: string;
	content: string;
}
interface IToCItem {
	root?: boolean;
	visible?: boolean;
	open?: boolean;
	id: string;
	level: number;
	text: string;
	childOf: string;
	hasChildren: boolean;
}

export default defineComponent({
	name: "ProductDocView",
	components: {
		BContainer,
		BSpinner,
		QMenu
	},
	props: {},
	setup() {
		const appStore = useAppStore();
		const globalStore = useGlobalStore();
		const docsStore = useDocsStore();
		const productId = computed(() => {
			return useRoute().params.productId as string | undefined;
		});
		const viewLoading = computed(() => globalStore.viewLoading);
		const currentSectionId = ref("");
		const originSectionId = ref("");
		const verticalMenuItems = ref<MenuItem[]>([]);
		const tocLevels = ref<IToCItem[]>([]);
		const tocSections = ref<ITocSections[]>([]);
		const content = ref("");
		const langCode = ref("");
		const route = useRoute();
		const router = useRouter();
		const activeHash = computed(() => router.currentRoute.value.hash);
		const getSections = (source: string): ITocSections[] => {
			const content = String(source);
			const sections: ITocSections[] = content
				.replace(/<h1/gm, "__SECTION__<h1")
				.split("__SECTION__")
				.filter((s) => s && s.substr(0, 2) === "<h")
				.map((s) => ({
					id: "",
					title: "",
					content: s
				}));
			const reFindSectionTitleText = /<h1.*id="(.*?)".*>(.*)<\/h1>/gm;
			let i = 0;
			let match = reFindSectionTitleText.exec(content);
			while (match != null) {
				sections[i].id = match[1];
				sections[i].title = match[2];
				match = reFindSectionTitleText.exec(content);
				i++;
			}
			return sections;
		};
		const genTocItems = (source: string) => {
			const fakeDoc = document.createElement("div");
			const content = $((fakeDoc.innerHTML = source) && fakeDoc);
			const headings = content.find("h1, h2, h3, h4, h5, h6");
			const levels: IToCItem[] = [];
			const setChildren = (item: IToCItem, index: number) => {
				if (!index) return;
				const prevLevels = levels.slice(0, index).reverse();
				prevLevels.every((prevLv) => {
					if (prevLv.level < item.level) {
						item.childOf = prevLv.id;
						prevLv.hasChildren = true;
						prevLv.open = false;
						item.visible = false;
						return false;
					}
					return true;
				});
			};
			headings.each((index: number, heading: any) => {
				const id = heading.id;
				const curLlv = +heading.tagName.toLowerCase().replace("h", "");
				levels.push({
					id,
					level: curLlv,
					text: heading.textContent as string,
					childOf: "",
					hasChildren: false,
					visible: true
				});
			});
			levels.forEach(setChildren);
			return levels;
		};
		const genMenuItems = (source: IToCItem[]): MenuItem[] => {
			const menuItems: MenuItem[] = [];

			source.forEach(tocItem => {
				const newItem: MenuItem = {
					name: tocItem.text,
					path: `#${tocItem.id}`,
					children: []
				};

				if (tocItem.level === 1)
					menuItems.push(newItem);
				else {
					const parentItems = tocItem.level === 2 ? menuItems : menuItems.flatMap(item => item.children || []);
					const parentItem = parentItems.find(item => item.path.replaceAll("#", "") === tocItem.childOf);

					if (parentItem)
						parentItem.children!.push(newItem);
				}
			});
			return menuItems;
		};

		const goToSection = async(tocItem: IToCItem, path: string) => {
			const doIt = (item: IToCItem, hash: string) => {
				const section = tocSections.value.find((l) => l.id === item.id);
				if (!section)
					return;
				if (section.id === currentSectionId.value)
					router.replace({ hash });

				currentSectionId.value = section.id;
				content.value = section.content;
			};

			let doItItem = clone(tocItem);
			if (tocItem.level !== 1) {
				const ids = [tocItem.id];
				do {
					doItItem = tocLevels.value.find(
						(l) => l.id === doItItem.childOf
					) as IToCItem;
					if (doItItem) ids.push(doItItem.id);
				} while (doItItem.level > 1);
			}
			doIt(doItItem, path);
		};
		const onClickMenuItem = (path: string) => {
			const tocItem = tocLevels.value.find((item) => item.id === path.replaceAll("#", ""));
			if (tocItem === undefined) return;

			goToSection(tocItem, path);
		};
		const toggleTocItem = (item: IToCItem) => {
			item.open = !item.open;
			const closeSubLevelsOf = (level: IToCItem) => {
				level.open = false;
				const children = tocLevels.value.filter((l) => l.childOf === level.id);
				children.forEach((l) => {
					l.open = false;
					l.visible = false;
					const chs = tocLevels.value.filter((tocL) => tocL.childOf === level.id);
					if (chs.length) chs.forEach(closeSubLevelsOf);
				});
			};
			tocLevels.value
				.filter((lvl) => lvl.childOf === item.id)
				.forEach((l) => {
					l.visible = Boolean(item.open);
					if (!item.open) closeSubLevelsOf(l);
				});
		};
		const selectUriSection = async() => {
			let sectionFullPath = location.hash.substring(1);
			let sectionUriParts = sectionFullPath.split("/");
			const aliasToMatch = sectionUriParts[0];
			if (aliasToMatch && aliasToMatch in SectionAliasesMap) {
				sectionFullPath = SectionAliasesMap[aliasToMatch];
				sectionUriParts = SectionAliasesMap[aliasToMatch].split("/");
			} else if (!sectionUriParts[0]) {
				sectionFullPath = tocSections.value[0].id;
				sectionUriParts = [tocSections.value[0].id];
			}
			let mainSectionId = sectionUriParts[0];
			const subSectionToc = sectionUriParts[1]
				? tocLevels.value.find(
					(s) => s.childOf === mainSectionId && s.id === sectionUriParts[1]
				)
				: null;
			const subSectionId = subSectionToc ? subSectionToc.id : "";
			let currentSection = tocSections.value.find((s) => s.id === mainSectionId);
			if (!currentSection) {
				mainSectionId = tocSections.value[0].id;
				currentSection = tocSections.value[0];
				sectionFullPath = mainSectionId;
			}
			currentSectionId.value = mainSectionId;
			content.value = currentSection.content;
			const currentTocLevel = tocLevels.value.find((s) => s.id === mainSectionId);
			if (currentTocLevel) toggleTocItem(currentTocLevel);
			if (!location.hash.startsWith("#" + sectionFullPath))
				router.replace({ hash: "#" + sectionFullPath });
			await nextTick();
			if (subSectionId)
				router.replace({ hash: "#" + subSectionId });
		};
		const evalContent = async() => {
			globalStore.setViewLoading(true);
			const currentProdId = route.params.productId;

			const doc = await docsStore.getProductIndex({
				product: currentProdId.toString(),
				langCode: langCode.value
			});
			if (!doc) {
				globalStore.setViewLoading(false);
				return;
			}

			await docsStore.syncProductSettings(currentProdId.toString());
			if (langCode.value !== doc.language) langCode.value = doc.language;
			const docContent = doc.content;
			originSectionId.value = String(currentSectionId.value);
			tocSections.value = getSections(docContent);
			tocLevels.value = genTocItems(docContent);
			verticalMenuItems.value = genMenuItems(tocLevels.value);
			selectUriSection();
			globalStore.setViewLoading(false);
		};
		onMounted(async() => {
			if (appStore.enableTrackLanding)
				trackUserEvent(productId.value === "jarvis" ? EventAI.LandFromJarvis : EventAI.LandFromQPortal);

			const langId = route.params.langCode as string;
			langCode.value = langId ?? ProductRepository.getDefaultLang();

			const fixed = productId.value?.toLowerCase() ?? "";
			if (productId.value !== fixed) {
				await router.replace({
					params: { productId: fixed },
					hash: location.hash
				});
			} else if (langCode.value === "null" || langCode.value === "undefined") {
				await router.replace({
					params: {
						productId: fixed,
						langCode: langCode.value
					},
					hash: location.hash
				});
			}

			await evalContent();
			EventManager.onLanguageChanged(async(newLang: string) => {
				langCode.value = newLang;
				await evalContent();
			});
		});
		return {
			activeHash,
			content,
			viewLoading,
			verticalMenuItems,
			onClickMenuItem
		};
	}
});
