import { DocProviderFactory, IProductSettings } from "@app-rest/doc-provider";
import { Action, Module, Mutation, VuexModule } from "vuex-class-modules";

import store from ".";

@Module class DocsModuleFactory extends VuexModule {
	rest: DocProviderFactory | null = null;
	product: string | null = null;
	productSettings: IProductSettings | null = null;

	@Mutation setRestClient(client: DocProviderFactory | null) {
		this.rest = client;
	}

	@Mutation setProduct(product: string | null) {
		this.product = product;
	}

	@Mutation setProductSettings(settings: IProductSettings | null) {
		this.productSettings = settings;
	}

	@Action ensureRestClient() {
		if (this.rest) return Promise.resolve(this.rest);
		const rest = new DocProviderFactory();
		this.setRestClient(rest);
		return Promise.resolve(rest);
	}

	@Action async syncProductSettings(productId: string) {
		if (this.product === productId && this.productSettings) return Promise.resolve();
		const rest = await this.ensureRestClient();
		const response = await rest.productSettings(productId);
		this.setProductSettings(response?.data ?? null);
		this.setProduct(productId);
	}

	@Action async getProductIndex({ product, langCode }: { product: string, langCode: string }) {
		const rest = await this.ensureRestClient();
		return rest.productIndex(product, langCode);
	}
}

const storeName = "docs";

let DocsStore: DocsModuleFactory | null;

function useDocsStore() {
	if (DocsStore) return DocsStore;

	const mod = DocsStore = new DocsModuleFactory({ name: storeName, store });

	return mod;
}

export default useDocsStore;
