import PortalRestClientFactory from ".";

export type AppEnv = "Develop" | "Staging" | "Production";
export type AppChannel = "Beta" | "Stable";
export type AppLaunchMode = "TestingSession" | "DevelopSession" | "DevelopAndTestingSession";

export interface IAppInstance {
	env: AppEnv | "";
	channel: AppChannel | "";
	version: string;
	launchMode: AppLaunchMode | "";
	isDevelopSession: boolean;
	isTestingSession: boolean;
	connectionString: string;
}
export default class RestAppFactory extends PortalRestClientFactory {
	getAppInfo() {
		return this.get<IAppInstance>("/api-app", {
			basePath: "",
			headers: new Headers({
				"Cache-Control": "no-cache, no-store",
				Pragma: "no-cache"
			})
		});
	}
}
