import { RestClient } from "scarlett";

export interface IResponseAny {
	<TData = any, TError = any>(prom: Promise<any>): Promise<
		[TData | null, TError | null]
	>;
}
export interface IResponseError<T, U = string | string[]> {
	Error: {
		Code: T,
		Details?: U,
		Message: string
	}
}
export const resolveAny: IResponseAny = (prom: Promise<any>) => {
	return new Promise<any>(resolve => {
		prom.then((response: any) =>
			resolve([response, null])
		).catch((err: any) => resolve([null, err]));
	});
};
export default class PortalRestClientFactory extends RestClient {
	constructor() {
		super({
			basePath: "/api/",
			throw: true,
			headers: new Headers({
				Pragma: "no-cache",
				"Cache-Control": "no-cache, no-store",
				"Content-Type": "application/json"
			}),
			credentials: "include",
			responseType: "json",
			redirect: "manual"
		});
	}
}
