import LangSelector from "@app-components/langSelector.vue";
import useAppStore from "@app-store/app";
import useGlobalStore from "@app-store/global";
import { EventAI, trackException, trackUserEvent } from "@app-utilities/app-insights";
import EventManager from "@app-utilities/event-manager";
import { BButton, QModal, QNavbar } from "@qamf/lighthouse";
import { debounce } from "lodash-es";
import { RestError } from "scarlett";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
interface IResponseError<T = string, U = string | string[]> {
	Error: {
		Code: T;
		Details?: U;
		Message: string;
	};
}
interface IErrorOccurrence {
	error: Error | RestError<IResponseError> | null;
	extras: any;
	datetime: Date;
}
export default defineComponent({
	name: "HelpSite",
	components: { QNavbar, BButton, QModal, LangSelector },
	props: {},
	setup() {
		const appStore = useAppStore();
		const globalStore = useGlobalStore();
		const route = useRoute();
		const env = computed(() => {
			return appStore.env;
		});
		const loading = computed(() => {
			return globalStore.Loading;
		});
		const viewLoading = computed(() => {
			return globalStore.viewLoading;
		});
		const errorModal = ref<any>();
		const fatalErrors = ref<IErrorOccurrence[]>([]);
		const showErrorModal = ref(false);
		const sectionTitle = ref("");
		const setSectionTitle = () => {
			sectionTitle.value = route.params.productId as string === "qportal" ? "QPortal" : "Jarvis";
		};
		const router = useRouter();
		const reload = () => {
			location.reload();
		};
		const errorIsRest = computed(() => {
			return fatalErrors.value[0] && fatalErrors.value[0].error instanceof RestError;
		});
		const errorStatusCode = computed(() => {
			return fatalErrors.value[0] && fatalErrors.value[0].error instanceof RestError
				? (fatalErrors.value[0].error.statusCode as number) ?? 0
				: 0;
		});
		const errorRestObject = computed(() => {
			return fatalErrors.value[0] && fatalErrors.value[0].error instanceof RestError
				? fatalErrors.value[0].error.data ?? null
				: null;
		});
		const resetFatalError = () => {
			fatalErrors.value = [];
		};
		const initErrorEvents = () => {
			const logErrors = debounce(() => {
				const printErrorEl = (el: IErrorOccurrence, title?: string) => {
					console.groupCollapsed(
						"\x1b[31m%s\x1b[0m",
						title ?? el.error?.message ?? "FatalError"
					);
					console.info(el.datetime);
					console.error(el.error);
					if (el.extras) console.info(el.extras);
					console.groupEnd();
				};
				if (fatalErrors.value.length === 1)
					printErrorEl(fatalErrors.value[0], "A fatal error occurred");
				else {
					console.groupCollapsed(
						"\x1b[31m%s\x1b[0m",
						`Intercepted fatal errors: ${fatalErrors.value.length}`
					);
					fatalErrors.value.forEach((er) => printErrorEl(er));
					console.groupEnd();
				}
			}, 1000);
			window.onerror = (message, source, lineno, colno, error) => {
				EventManager.emitError(error as Error, {
					message,
					source,
					lineno,
					colno
				});
				return true;
			};
			window.onunhandledrejection = (event: PromiseRejectionEvent) => {
				event.preventDefault();
				if (event.reason instanceof Error || event.reason instanceof RestError)
					EventManager.emitError(event.reason);
				else {
					let message = "";
					try {
						message = JSON.stringify(event.reason);
					} catch (e) { }
					EventManager.emitError({
						name: "UnhandledPromiseRejection",
						message
					});
				}
			};
			EventManager.onError(async(error, extras) => {
				const tracked = await trackException(error, extras);
				fatalErrors.value.push({ error, extras, datetime: new Date() });
				logErrors();

				if (showErrorModal.value) return;

				trackUserEvent(EventAI.FatalError, {
					description: "Unhandled exception feedback to user",
					error: tracked?.exception,
					errorName: tracked?.exception.name,
					errorStack: tracked?.exception.stack,
					errorMessage: tracked?.exception.message,
					errorExtras: tracked?.properties
				});

				if (router.currentRoute.value.name !== "error-page")
					showErrorModal.value = true;
			});
		};
		const errorRestCode = computed(() => {
			return errorRestObject.value && errorRestObject.value.Error
				? errorRestObject.value.Error.Code
				: "";
		});
		const titleErrorModal = computed(() => {
			if (errorRestCode.value === "QCenterNotFound") return "Center not found";
			else {
				switch (errorStatusCode.value) {
					case 500:
						return "Something went wrong";
					case 503:
						return "Service Unavailable";
					case 401:
						return "Unauthorized access";
					case 404:
						return "Resource not found";
					case 440:
						return "Session Expired";
					default:
						return "An error occurred";
				}
			}
		});
		onMounted(async() => {
			initErrorEvents();
			await appStore.syncData();
			setSectionTitle();
			globalStore.setLoading(false);
		});
		return {
			env,
			loading,
			errorModal,
			fatalErrors,
			showErrorModal,
			reload,
			errorIsRest,
			errorStatusCode,
			errorRestObject,
			resetFatalError,
			titleErrorModal,
			viewLoading,
			sectionTitle
		};
	}
});
