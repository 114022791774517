import router from "@app-router/index";
import useAppStore from "@app-store/app";
import { ApplicationInsights, IConfig, IConfiguration } from "@microsoft/applicationinsights-web";
import { RestError } from "scarlett";

import { IErrorEventHandler } from "./event-manager";

type TrackedException = {
	exception: Error | DOMException;
	properties: Record<string, any>;
}
type TrackedPageEvent = "start" | "end";

export enum EventAI {
	FatalError = "fatal-error",
	LandFromQPortal = "land-from-qportal",
	LandFromJarvis = "land-from-jarvis"
}

export let AppInsights: ApplicationInsights;

export function setupAppInsights(config: IConfiguration & IConfig) {
	if (AppInsights) return;
	AppInsights = new ApplicationInsights({ config });
	AppInsights.loadAppInsights();
}
export function trackView(name: string, action: TrackedPageEvent, path: string) {
	if (!AppInsights) return;

	switch (action) {
		case "start": AppInsights.startTrackPage(name); break;
		case "end": AppInsights.stopTrackPage(name, path); break;
	}
}
export async function trackUserEvent(name: EventAI, extraProps?: Record<string, any>) {
	await useAppStore().syncData();
	if (!AppInsights) return;

	const properties = Object.assign(
		{},
		extraProps || {},
		{ path: router.currentRoute.value.fullPath }
	);
	AppInsights.trackEvent({ name, properties });
}
export async function trackException(error: Parameters<IErrorEventHandler>[0], extras?: Parameters<IErrorEventHandler>[1]): Promise<TrackedException | undefined> {
	if (!AppInsights) return;

	const tracked: TrackedException = { exception: error, properties: (extras ?? {}) };

	if (!extras)
		extras = {};

	if (tracked.exception instanceof RestError) {
		let rawBody = "";
		try {
			rawBody = await tracked.exception.fetchResponse?.text() ?? "";
		} catch (e) {}

		extras.instanceOf = "RestError";
		const api = {
			url: tracked.exception.request?.url.toString(),
			method: tracked.exception.request?.method,
			params: tracked.exception.request?.options.query,
			payload: tracked.exception.request?.body,
			headers_request: tracked.exception.request?.options.headers,
			headers_response: tracked.exception.fetchResponse?.headers,
			status_code: tracked.exception.statusCode,
			error_code: tracked.exception.code,
			response: tracked.exception.data,
			rawBody
		};
		const isTimeout = (tracked.exception.statusCode + "").toLowerCase() === "timeout";
		if (tracked.exception.statusCode && tracked.exception.request?.method && (isTimeout || !isNaN(+tracked.exception.statusCode)))
			tracked.exception.message = `[${tracked.exception.request.method.toUpperCase()}-${api.status_code}] ${tracked.exception.request?.url.pathname}`;
	} else if (error) {
		tracked.properties.code = error.name;
		const exceptionTypes = [
			DOMException,
			EvalError,
			RangeError,
			ReferenceError,
			SyntaxError,
			TypeError,
			URIError,
			AggregateError
		];
		for (let i = 0; i < exceptionTypes.length; i++) {
			if (error instanceof exceptionTypes[i]) {
				tracked.properties.instanceOf = exceptionTypes[i].name;
				break;
			}
		}
	}
	AppInsights.trackException(tracked);
	AppInsights.flush();
	return tracked;
}
