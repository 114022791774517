import { ConstructorOptions, EventEmitter2 } from "eventemitter2";

export interface IErrorEventHandler {
	(errorObj: Error, extra?: any);
}
export interface IExtraObject {
	type?: string;
	[key: string]: any;
}
export interface ILanguageChangedHandler {
	(code: string);
}
class EventManagerFactory extends EventEmitter2 {
	constructor(options?: ConstructorOptions) {
		super(options);
		this.setMaxListeners(0);
	}

	emitWindowResize() {
		return super.emit("window-resize", window.innerWidth, window.innerHeight);
	}

	emitError(error: Parameters<IErrorEventHandler>[0], extra?: Parameters<IErrorEventHandler>[1]) {
		return this.emit("fatal-error", error, extra);
	}

	onError(handler: IErrorEventHandler) {
		this.on("fatal-error", handler);
	}

	emitLanguageChanged(id: string) {
		return this.emit("lang-changed", id);
	}

	onLanguageChanged(handler: ILanguageChangedHandler) {
		const listener = this.on("lang-changed", handler);
		return () => listener.off("lang-changed", handler);
	}
}
const EventManager = new EventManagerFactory();
export default EventManager;
