<template>
	<div>
		<b-form
			v-if="productSettings && productSettings.Languages.length > 1"
			inline
		>
			<b-form-select
				v-model="langCode"
				name="lang-selector"
				:options="productSettings.Languages"
				@input="onClickLang"
			/>
		</b-form>
	</div>
</template>
<script lang="ts" src="./langSelector.ts"></script>
