<template>
	<div class="page content not-found">
		<b-container>
			<br><br><br>
			<h4 class="h4 text-center">
				<i class="fal fa-exclamation-triangle mr-2" />Page not found<br>
			</h4>
			<br>
			<div class="extra-message">
				Sorry, we couldn't find the page you were looking for.
			</div>
		</b-container>
	</div>
</template>

<style lang="scss">
.page.not-found {
	.h4 {
		color: #c30017;
	}
	.extra-message {
		text-align: center;
		font-weight: 300;
	}
}
</style>
