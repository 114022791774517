<!--eslint-disable vue/no-v-html-->
<template>
	<div>
		<div
			v-if="viewLoading"
			class="d-flex align-self-center w-100 justify-content-around"
		>
			<b-spinner />
		</div>
		<b-container
			v-else
			fluid="xl"
			class="page product-doc"
		>
			<div class="doc-content">
				<QMenu
					title="On this page"
					:active-menu-path="activeHash"
					:items="verticalMenuItems"
					collapsable
					@click-menu-item="onClickMenuItem"
				/>
				<b-container
					fluid
					class="content-wrapper"
				>
					<div
						class="content"
						v-html="content"
					/>
				</b-container>
			</div>
		</b-container>
	</div>
</template>

<script lang="ts" src="./product-doc.ts"></script>
