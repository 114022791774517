import { RouteRecordRaw } from "vue-router";
import { Module, Mutation, VuexModule } from "vuex-class-modules";

import store from ".";

@Module class GlobalModuleFactory extends VuexModule {
	Loading = true;
	viewLoading = false;
	Routes = [] as RouteRecordRaw[];

	@Mutation setLoading(value: boolean) {
		this.Loading = value;
	}

	@Mutation setViewLoading(value: boolean) {
		this.viewLoading = value;
	}

	@Mutation setRoutes(routes: RouteRecordRaw[]) {
		this.Routes = routes;
	}

	@Mutation setRouteTitle(obj: { routeName: string, title: string }) {
		if (!obj.title || !obj.title.trim()) return;
		const route = this.Routes.find(r => r.name === obj.routeName);
		if (!route || route.meta?.label === obj.title) return;
		if (!route.meta) return;
		route.meta.label = obj.title;
	}
}

const storeName = "global";

let GlobalStore: GlobalModuleFactory | null;

function useGlobalStore() {
	if (GlobalStore) return GlobalStore;

	const mod = GlobalStore = new GlobalModuleFactory({ name: storeName, store });

	return mod;
}

export default useGlobalStore;
