export default {
	// QPortal
	tcs: "performance-monitoring",

	// Jarvis
	"crm-account": "eula-management-jarvis-crm",
	"companies-search": "companies-sites",
	"company-and-site-page": "companies-sites/company-details",
	"company-page": "companies-sites/company-details",
	"site-page": "companies-sites/company-details",
	systems: "system/system-list",
	"single-system": "system/system-details",
	cmp: "conqueror-maximization-program",
	"single-system-cmp": "conqueror-maximization-program"
};
