export interface IProduct {
	id: string;
	name: string;
}

interface IProductFind {
	(product: IProduct): boolean
}

class ProductRepositoryFactory {
	private db: IProduct[] = [
		{
			id: "qportal",
			name: "QPortal"
		},
		{
			id: "jarvis",
			name: "Jarvis"
		}
	];

	resolveName(id: string) {
		const product = this.db.find(p => p.id === id);
		if (product) return product.name;
	}

	first() {
		return this.db[0];
	}

	find(cb: IProductFind) {
		return this.db.find(cb);
	}

	getDefaultLang() {
		return "en-US";
	}
}

export const ProductRepository = new ProductRepositoryFactory();
