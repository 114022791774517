import router, { Routes } from "@app-router/index";
import useGlobalStore from "@app-store/global";
import store from "@app-store/index";
import EventManager from "@app-utilities/event-manager";
import { createApp } from "vue";

import AppEntryPoint from "./app.vue";

const HelpSiteApp = createApp(AppEntryPoint);
HelpSiteApp.use(router);
HelpSiteApp.use(store);
HelpSiteApp.mount("#app-main");

Routes.forEach(route => router.addRoute(route));
useGlobalStore().setRoutes(Routes);
HelpSiteApp.config.errorHandler = (err, vm, info) => {
	EventManager.emitError(err as Error, { info });
};
export default HelpSiteApp;
