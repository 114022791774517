import useAppStore from "@app-store/app";
import useGlobalStore from "@app-store/global";
import { trackView } from "@app-utilities/app-insights";
import { ProductRepository } from "@app-utilities/products";
import Home from "@app-views/home.vue";
import NotFound from "@app-views/not-found.vue";
import Product from "@app-views/product-doc.vue";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

export const Routes: RouteRecordRaw[] = [
	{
		path: "/",
		name: "home",
		component: Home,
		meta: { label: "" }
	},
	{
		path: "/:langCode/:productId",
		name: "product",
		component: Product,
		meta: { label: "" }
	},
	{
		path: "/:catchAll(.*)",
		name: "not-found",
		component: NotFound,
		meta: {
			label: "Not Found"
		}
	}
];

const router = createRouter({
	history: createWebHistory(),
	linkActiveClass: "active",
	routes: Routes
});

router.beforeEach((to, from, next) => {
	if (to.name === "home") {
		useAppStore().setEnableTrackLanding(false);
		return next({
			name: "product",
			params: {
				langCode: ProductRepository.getDefaultLang(),
				productId: ProductRepository.first().id
			}
		});
	}

	const globalStore = useGlobalStore();
	if (to.name === "product") {
		const productName = ProductRepository.resolveName(to.params.productId.toString()) ?? "";
		globalStore.setRouteTitle({ routeName: to.name, title: productName });
	} else globalStore.setRouteTitle({ routeName: to.name?.toString() ?? "", title: to.meta?.label as string ?? "" });

	if (to.name)
		trackView(to.name.toString(), "start", to.fullPath);

	next();
});

router.afterEach((to) => {
	if (to.name)
		trackView(to.name.toString(), "end", to.fullPath);
});

export default router;
