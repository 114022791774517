import useDocsStore from "@app-store/docs";
import EventManager from "@app-utilities/event-manager";
import { BForm, BFormSelect, BLink } from "bootstrap-vue-next";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
	name: "LangSelector",
	components: {
		BLink,
		BForm,
		BFormSelect
	},
	props: {},
	setup() {
		const docsStore = useDocsStore();
		const productSettings = computed(() => {
			return docsStore.productSettings;
		});
		const route = useRoute();
		const router = useRouter();
		const langCode = ref("");
		const onClickLang = async(value: unknown) => {
			EventManager.emitLanguageChanged(value as string);

			await router.replace({
				params: { ...route.params, langCode: value as string },
				hash: location.hash
			});
		};

		onMounted(() => {
			langCode.value = route.params.langCode as string;
		});
		return {
			productSettings,
			langCode,
			onClickLang
		};
	}
});
