<template>
	<div
		class="main"
		:class="{ 'loading': loading }"
	>
		<div
			v-if="loading"
			class="splash-screen"
		>
			<span class="splash-content">
				<img
					id="loaderLogo"
					class="img-fluid"
					src="/assets/images/qubica_logo.png"
				>
				<div class="splash-spinner">
					<div class="bounce1" />
					<div class="bounce2" />
					<div class="bounce3" />
				</div>
			</span>
		</div>

		<div v-else>
			<QNavbar
				title="Helpsite"
				:brand-to-path="''"
				sticky
				hide-toggler
			>
				<template #brand-image>
					<img
						src="/assets/images/qubica_logo.png"
						width="44"
					>
				</template>
				<template #start>
					<span>{{ sectionTitle }}</span>
				</template>
				<template #end>
					<LangSelector />
				</template>
			</QNavbar>
			<div
				class="section-content"
				:env="env"
			>
				<router-view ref="currentView" />
			</div>
		</div>
		<QModal
			ref="errorModal"
			v-model="showErrorModal"
			centered
			scrollable
			no-close-on-backdrop
			hide-footer
			modal-class="modal-error"
			header-bg-variant="danger"
			:title="titleErrorModal"
			@hidden="resetFatalError()"
		>
			<div v-if="errorIsRest">
				<div v-if="errorStatusCode === 500 || errorStatusCode === 503">
					<p v-if="errorStatusCode === 500">
						The system could not process your request.
					</p>
					<p v-else>
						The server is currently unable to handle the request due to a temporary maintenance of the server.
					</p>
					<p>
						This may be caused by abnormal network traffic or a problem in our system configuration.<br><br>
						Our development team has automatically been notified of this issue and will investigate.<br><br>
						Click the Reload button to refresh the page or try again later.<br><br>
						We apologize for the inconvenience.
					</p>
					<div class="buttons-area text-right">
						<b-button
							variant="outline-secondary"
							size="sm"
							@click="reload"
						>
							Reload
						</b-button>
					</div>
				</div>
				<div v-else-if="errorStatusCode == 401">
					<p>
						Access is denied as you do not have sufficient privileges.
						<br><br>
						Please logout and try again using different credentials.
					</p>

					<div class="buttons-area text-right">
						<b-button
							variant="outline-secondary"
							size="sm"
							href="/logout"
						>
							Logout
						</b-button>
					</div>
				</div>
				<div v-else-if="errorStatusCode == 404">
					<p>Resource not found</p>
					<div class="buttons-area text-right">
						<b-button
							variant="outline-secondary"
							size="sm"
							@click="showErrorModal=false"
						>
							Ok
						</b-button>
					</div>
				</div>
				<div v-else>
					An error occurred
					<div
						v-if="errorRestObject && errorRestObject.Error"
						class="backend-error-message"
					>
						{{ errorRestObject.Error.Message }}
					</div>
					<div
						v-else-if="fatalErrors[0]"
						class="backend-error-message"
					>
						{{ fatalErrors[0].error?.message }}
					</div>
					<div
						v-else
						class="backend-error-message"
					>
						{{ errorRestObject }}
					</div>
					<div class="buttons-area text-right">
						<b-button
							variant="outline-secondary"
							size="sm"
							@click="showErrorModal=false"
						>
							Ok
						</b-button>
					</div>
				</div>
			</div>
			<div v-else>
				<div
					v-if="errorRestObject && errorRestObject.Error"
					class="error-message"
				>
					{{ errorRestObject.Error.Message }}
				</div>
				<div v-else>
					Our development team has automatically been notified of this issue and will investigate.
					<br><br>
					Click the Reload button to refresh the page or try again later.
					<br><br>
					We apologize for the inconvenience.
					<div class="buttons-area text-right">
						<b-button
							variant="outline-secondary"
							size="sm"
							@click="reload"
						>
							Reload
						</b-button>
					</div>
				</div>
			</div>
		</QModal>
	</div>
</template>
<script lang="ts" src="./app.ts"></script>
