import RestAppFactory, { AppChannel, AppEnv, AppLaunchMode, IAppInstance } from "@app-rest/app";
import { setupAppInsights } from "@app-utilities/app-insights";
import { Action, Module, Mutation, VuexModule } from "vuex-class-modules";

import store from ".";

let ensureDataPromise: null | Promise<void> = null;

@Module class AppModuleFactory extends VuexModule implements IAppInstance {
	env: "" | AppEnv = "";
	channel: "" | AppChannel = "";
	version = "";
	isDevelopSession = false;
	isTestingSession = false;
	launchMode: "" | AppLaunchMode = "";
	connectionString = "";
	enableTrackLanding = true;

	@Mutation setEnableTrackLanding(state: boolean) {
		this.enableTrackLanding = state;
	}

	@Mutation updateData(appData: IAppInstance) {
		Object.keys(appData).forEach(prop => {
			this[prop] = appData[prop];
		});
	}

	@Action async syncData() {
		if (!ensureDataPromise) {
			ensureDataPromise = new Promise<void>(async resolve => {
				if (this.version) return resolve();
				const client = new RestAppFactory();
				const response = await client.getAppInfo();
				if (!response.data) {
					ensureDataPromise = null;
					return resolve();
				}
				this.updateData(response.data);
				setupAppInsights({
					connectionString: this.connectionString,
					maxAjaxCallsPerView: -1,
					disableExceptionTracking: true,
					disableTelemetry: this.launchMode === "DevelopAndTestingSession"
				});
				resolve();
			});
		}
		await ensureDataPromise;
	}
}

const storeName = "app";

let AppStore: AppModuleFactory | null;

function useAppStore() {
	if (AppStore) return AppStore;

	const mod = AppStore = new AppModuleFactory({ name: storeName, store });

	return mod;
}

export default useAppStore;
