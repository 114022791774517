import { ProductRepository } from "../utilities/products";
import PortalRestClientFactory from ".";

export interface IProductInfo {
	Version: string;
}
export interface IProductSettings {
	Languages: string[];
}
export interface IProduct {
	info: IProductInfo;
	settings: IProductSettings;
}
export interface IDocumentResult {
	language: string;
	content: string;
}

export class DocProviderFactory extends PortalRestClientFactory {
	constructor() {
		super();
		this.options.set("basePath", "/api-doc-provider");
		this.options.set("cacheInMemory", true);
	}

	private fixImagesSrc(source: string, docBaseUrl: string) {
		const reFindRelativeUrl = /(src=)"(images\/[^']*?)"/gm;
		let match = reFindRelativeUrl.exec(source);
		while (match != null) {
			const fixedUrl = `${docBaseUrl}/${match[2]}`;
			source = source.replace(match[2], fixedUrl);
			match = reFindRelativeUrl.exec(source);
		}
		return source;
	}

	private productResourceURI(product: string, info: IProductInfo) {
		return `/${product}/${info.Version}`;
	}

	private async productIndexDefaultLang(product: string, info: IProductInfo) {
		const productUri = this.productResourceURI(product, info);
		return this.get<string>(`${productUri}/${ProductRepository.getDefaultLang()}/index.html`);
	}

	private productInfo(product: string) {
		return this.get<IProductInfo>(`/${product}/info.json`);
	}

	private getHtmlContent(path: string) {
		return this.get<string>(path, {
			responseType: "text",
			throwExcluding: [{ statusCode: 404 }]
		});
	}

	async productSettings(product: string) {
		const prodInfoResponse = await this.productInfo(product);
		const info = prodInfoResponse.data;
		if (!info) return;
		return this.get<IProductSettings>(this.productResourceURI(product, info) + "/info.json");
	}

	async productIndex(product: string, language = ""): Promise<IDocumentResult> {
		const defaultLang = ProductRepository.getDefaultLang();
		const productInfoResponse = await this.productInfo(product);
		const info = productInfoResponse?.data;
		if (!language) language = ProductRepository.getDefaultLang();
		if (!productInfoResponse || !info)
			throw new Error("Product not found");

		const productUri = this.productResourceURI(product, info);

		const exit = (result: IDocumentResult) => {
			const fullBaseUri = `${this.options.get("basePath")}${productUri}/${result.language}`;
			result.content = this.fixImagesSrc(result.content, fullBaseUri);
			return result;
		};
		const exitWithDefault = async() => {
			const res = await this.productIndexDefaultLang(product, info);
			return exit({ content: res.data ?? "", language: defaultLang });
		};

		const firstResponse = await this.getHtmlContent(`${productUri}/${language}/index.html`);
		if (firstResponse.data) {
			return exit({
				content: firstResponse.data,
				language
			});
		}

		const productSettings = (await this.productSettings(product))?.data;
		const langParts = language.split("-");
		const matchingLang = productSettings?.Languages.find(l => l.indexOf(langParts[0] + "-") > -1);
		if (!matchingLang)
			return exitWithDefault();

		const secondResponse = await this.getHtmlContent(`${productUri}/${matchingLang}/index.html`);
		if (secondResponse.data) {
			return exit({
				content: secondResponse.data,
				language: matchingLang
			});
		}

		return exitWithDefault();
	}
}
